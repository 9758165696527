import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 360 360"
    fill={fill}
    {...rest}
  >
    <path
      d="M204 179l93-92a18 18 0 1 0-26-25l-92 92-92-92a18 18 0 0 0-26 25l93 92-93 93a18 18 0 1 0 26 25l92-92 92 92a18 18 0 0 0 26 0c7-7 7-18 0-25l-93-93z"
    />
  </svg>
)
