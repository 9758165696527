import React from 'react'
import styled, { css } from 'styled-components'
import { rem, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Layout, { Main } from 'layouts/default'
import Head from 'components/head'
import BlogMenuSidebar from 'components/blog-menu-sidebar'
import BlogMenuMobile from 'components/blog-menu-mobile'
import HeaderTitle, { HeaderTitleWrap } from 'components/header-title'
import Comments from 'components/comments'

const { colors, fonts } = theme
const { silver } = colors

const Container = styled.div`
  margin: 0 auto;
  max-width: ${rem(1000)};

  ${HeaderTitleWrap} h1 {
    padding-left: ${rem(24)};
  }

  ${media.medium`
    text-align: left;
  `}
`

const Body = styled.div`
  ${flex('column', 'center', 'flex-start')}
  margin: ${rem(48)} auto 0;

  ${media.medium`
    ${flex('row', 'flex-start', 'space-between')}
  `}
`

const Article = styled.article`
  margin: 0 auto;
  text-align: left;
  width: 100%;

  p {
    max-width: ${rem(600)};
  }

  ${media.medium`
    margin: 0;
    width: calc(100% - ${rem(344)});
  `}
`

const Aside = styled.aside`
  display: none;

  ${media.medium`
    display: block;
    margin-top: 0;
    width: ${rem(280)};
  `}
`

const subtitleCss = css`
  color: ${silver};
  font-size: ${rem(14)};
  font-style: italic;
  display: block;
  margin-top: ${rem(8)};

  :before {
    content: '\u2919';
    margin-right: ${rem(4)};
  }

  :after {
    content: '\u291A';
    margin-left: ${rem(4)};
  }

  ${media.medium`
    :before,
    :after {
      display: none;
    }
  `}
`

const Subtitle = styled.h2`
  ${subtitleCss}
  font-family: ${fonts.primary};
  font-weight: 400;
`

const PublishDate = styled.time`
  ${subtitleCss}
`

export default ({ title, subtitle, description, article, children }) => {
  const currentPath = article ? article.path : ''
  const pageTitle = article ? article.title : title
  const metaDescription = article ? article.description : description
  const hasHeaderTitle = !!article && !!article.image

  return (
    <Layout>
      <Head title={pageTitle} description={metaDescription} />
      {article && <BlogMenuMobile currentPath={currentPath} />}
      {hasHeaderTitle && (
        <HeaderTitle title={pageTitle} images={[article.image]} />
      )}
      <Main>
        <Container>
          {!hasHeaderTitle && <h1>{pageTitle}</h1>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {article && <PublishDate>{article.date}</PublishDate>}
          <Body>
            <Article>{children}</Article>
            <Aside>
              <BlogMenuSidebar currentPath={currentPath} />
            </Aside>
          </Body>
        </Container>
      </Main>
      {currentPath && <Comments postId={currentPath} />}
    </Layout>
  )
}
