import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { is, rem, flex, position, transparentize } from 'styled-tidy'
import { Link } from 'gatsby'
import fetchArticles from 'lib/fetch-articles'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import pillLink from 'lib/styles/pill-link'
import linkCircle from 'lib/styles/link-circle'
import stopEvent from 'lib/stop-event'
import Arrow from 'components/icons/arrow'
import Hamburger from 'components/icons/hamburger'
import Close from 'components/icons/close'

const { primary, white, ghost, bone, tundra, slate, black } = theme.colors

const Container = styled.div`
  ${flex('row', 'center', 'space-between')}
  ${linkCircle}
  padding: ${rem(8)};
  user-select: none;
  width: calc(100% + ${rem(4)});

  a {
    ${pillLink}
    box-shadow: inset 0 0 0 ${rem(2)} ${white};
    height: ${rem(50)};
    margin: 0;
    outline: none;
    padding: 0;
    width: ${rem(50)};

    :focus {
      background: ${ghost};
      color: ${black};
    }
  }

  ${media.medium`
    display: none;
  `}
`

const Menu = styled.div`
  ${position('fixed', 0, 'auto', 'auto', '100%')}
  background: ${white};
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  transition: all 500ms ease;
  user-select: none;
  width: 100vw;
  z-index: 10;

  ul {
    ${flex('column', 'flex-start', 'flex-start')}
    height: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    margin: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  li {
    display: block;
    width: 100%;

    > a,
    > span {
      position: relative;
      z-index: 2;
    }

    :last-child {
      > a,
      > span {
        &,
        :hover,
        :focus {
          box-shadow: 0 0 ${rem(96)} ${transparentize(black, 0.8)};
          z-index: 1;
        }
      }
    }

    a,
    span {
      ${flex('row', 'center', 'flex-start')}
      background: ${white};
      border-bottom: ${rem(1)} solid ${bone};
      border-radius: 0;
      padding: ${rem(16)};
      width: 100%;
      text-align: left;
      text-decoration: none;
    }

    a {
      color: ${slate};
      font-weight: 700;
      outline: none;

      :hover,
      :focus {
        background: ${bone};
        box-shadow: none;
        color: ${black};
      }
    }

    span {
      color: ${tundra};
      cursor: default;
    }
  }

  > ul > li:first-child {
    > span {
      background: none;
      border-bottom: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: auto;
      z-index: 4;
    }

    > a {
      justify-content: flex-end;
    }
  }

  ${is('open')`
    box-shadow: 0 0 ${rem(240)} ${transparentize(black, 0.5)};
    pointer-events: all;
    transform: translateX(-100vw);
  `}
`

const SubMenu = styled.ul`
  background: ${white};
  box-shadow: ${rem(1)} 0 0 ${slate};
  left: 100%;
  transition: transform 500ms ease;
  z-index: 5;

  a,
  span {
    padding-left: ${rem(52)};
  }

  li :first-child {
    position: sticky;
    top: 0;
    z-index: 3;

    a {
      color: ${tundra};
      font-weight: 400;
      padding-left: ${rem(16)};

      svg {
        margin-right: ${rem(16)};
      }

      :hover,
      :focus {
        color: ${black};
      }
    }
  }

  ${is('open')`
    transform: translateX(-100%);
    box-shadow: 0 0 ${rem(64)} ${transparentize(black, 0.8)};
  `}
`

export default ({ currentPath = '' }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [openYear, setOpenYear] = useState('')
  const articles = fetchArticles()
  const articlesByYear = []

  const handleHamburgerClick = event => {
    stopEvent(event)
    setIsMenuOpen(true)
    event.currentTarget.blur()
  }
  const handleYearTouch = year => event => {
    setOpenYear(year === openYear ? '' : year)
    event.currentTarget.blur()
  }
  const handleBackTouch = event => {
    setOpenYear('')
    event.currentTarget.blur()
  }
  const handleCloseTouch = event => {
    setIsMenuOpen(false)
    setOpenYear('')
    event.currentTarget.blur()
  }

  useEffect(() => {
    const body = document.querySelector('body')

    if (isMenuOpen) {
      body.style.position = 'fixed'
      body.style.overflow = 'hidden'
    } else {
      body.style.position = ''
      body.style.overflow = ''
    }
  }, [isMenuOpen])

  articles.forEach(article => {
    const first = articlesByYear[0]
    if (!first || first.year !== article.year) {
      articlesByYear.unshift({
        year: article.year,
        articles: [article],
      })
    } else {
      first.articles.unshift(article)
    }
  })

  return (
    <>
      <Container>
        <Link to="/blog" aria-label="Back">
          <Arrow size={20} />
        </Link>
        <Link to="/blog" aria-label="Blog Menu" onClick={handleHamburgerClick}>
          <Hamburger size={20} />
        </Link>
      </Container>
      <Menu open={isMenuOpen ? 1 : 0}>
        <ul>
          <li>
            <a
              role="button"
              aria-label="Close Menu"
              tabIndex={0}
              onClick={handleCloseTouch}
              onKeyPress={handleCloseTouch}
            >
              <Close size={20} fill={primary} />
            </a>
            <span>Blog Menu</span>
          </li>
          {articlesByYear.map(({ year, articles }) => (
            <li key={year}>
              <a
                role="button"
                tabIndex={0}
                current={year === openYear ? 1 : 0}
                onClick={handleYearTouch(year)}
                onKeyPress={handleYearTouch(year)}
              >
                {year}
              </a>
              <SubMenu open={year === openYear}>
                <li>
                  <a
                    role="button"
                    aria-label="Back"
                    tabIndex={0}
                    onClick={handleBackTouch}
                    onKeyPress={handleBackTouch}
                  >
                    <Arrow size={20} fill={primary} />
                    {year}
                  </a>
                </li>
                {articles.map(({ title, path }) => (
                  <li key={path}>
                    {path === currentPath ? (
                      <span>{title}</span>
                    ) : (
                      <Link to={path}>{title}</Link>
                    )}
                  </li>
                ))}
              </SubMenu>
            </li>
          ))}
        </ul>
      </Menu>
    </>
  )
}
