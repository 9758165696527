import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/blog'

export default ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout article={frontmatter}>
      <section dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image
      }
    }
  }
`
