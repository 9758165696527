import React from 'react'
import theme from 'lib/styles/theme'

export default ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 36 36"
    fill={fill}
    {...rest}
  >
    <path d="M34.459 1.375a2.999 2.999 0 00-4.149.884L13.5 28.17l-8.198-7.58a2.999 2.999 0 10-4.073 4.405l10.764 9.952s.309.266.452.359a2.999 2.999 0 004.15-.884L35.343 5.524a2.999 2.999 0 00-.884-4.149z" />
  </svg>
)
