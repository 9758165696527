import React from 'react'
import styled from 'styled-components'
import { rem, position, is, isnt, flex, value } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { white, ghost, bone, silver, coal, black, primary } = theme.colors

const Button = styled.button`
  ${flex('row', 'center', 'center')}
  border: 0;
  border-radius: ${rem(20)};
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: 1;
  height: ${rem(40)};
  padding: 0 ${rem(16)};
  position: relative;
  text-decoration: none;
  transition: all 250ms ease;
  user-select: none;

  > span {
    ${flex('row', 'center', 'center')}
    height: 100%;
  }

  &[type='submit'] {
    padding: 0 ${rem(40)} 0 ${rem(16)};
  }

  svg {
    ${position('absolute', 12, 16, 'auto', 'auto')}
    transition: transform 250ms ease;
  }

  :hover svg {
    transform: translateX(${rem(4)});
  }

  :active svg {
    transform: translateX(${rem(8)});
  }

  :disabled {
    pointer-events: none;
  }

  ${isnt('as', 'button')`
    ${flex('row', 'center', 'center')}
  `}

  ${is('kind', 'default')`
    background: ${ghost};
    color: ${coal};

    svg {
      fill: ${coal};
    }
    :hover {
      box-shadow: none;
      color: ${black};

      svg {
        fill: ${black};
      }
    }
    :disabled {
      background: ${bone};
      color: ${silver};
    }
  `}

  ${is('kind', 'primary')`
    background: ${coal};
    color: ${white};

    svg {
      fill: ${white};
    }
    :hover {
      background: ${primary};
      color: ${white};
    }
    :focus {
      box-shadow: 0 0 0 ${rem(2)} ${white}, 0 0 0 ${rem(4)} ${bone};
    }
    :disabled {
      background: ${silver};
    }
  `}

  ${is('kind', 'outline')`
    background: transparent;
    box-shadow: inset 0 0 0 ${rem(1)} ${ghost};
    color: ${ghost};

    svg {
      fill: ${coal};
    }

    :hover,
    :focus {
      box-shadow: none;
      color: ${black};

      svg {
        fill: ${black};
      }
    }
    :disabled {
      background: ${bone};
      color: ${silver};
    }
  `}

  ${is('outlineColor')`
    box-shadow: inset 0 0 0 ${rem(1)} ${value('outlineColor')};
    color: ${value('outlineColor')};

    svg {
      fill: ${value('outlineColor')};
    }
  `}
`

export default ({ as = 'button', kind = 'default', children, ...rest }) => (
  <Button as={as} kind={kind} {...rest}>
    {as === 'button' ? <span>{children}</span> : children}
  </Button>
)
