import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useStaticQuery } from 'gatsby'
import { inject, observer } from 'mobx-react'
import styled, { css } from 'styled-components'
import { rem, flex, position, is, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Arrow from 'components/icons/arrow'
import Spinner, { SpinnerWrapper } from 'components/icons/spinner'
import Check from 'components/icons/check'
import Button from 'components/button'
import Recaptcha from 'react-recaptcha'

const { colors, fonts } = theme
const { white, bone, silver, slate, primary } = colors

const Done = styled.span.attrs({
  children: (
    <Check size={64} fill={primary} aria-label="Your comment has been posted" />
  ),
})``

const maskCss = css`
  :after {
    ${position('absolute', -16)}
    background: ${transparentize(white, 0.3)};
    content: '';
  }
`

const Form = styled.form`
  position: relative;
  width: 100%;

  p {
    ${flex('column-reverse', 'flex-start', 'flex-start')}
    padding: 0;
    margin: 0 0 ${rem(16)};
  }

  label {
    color: ${silver};
    font-size: ${rem(14)};
    transition: color 250ms ease;
    user-select: none;
  }

  input,
  textarea {
    border: ${rem(1)} solid ${bone};
    border-radius: ${rem(8)};
    font-family: ${fonts.primary};
    font-size: ${rem(16)};
    padding: ${rem(8)};
    max-width: 100%;
    min-width: 100%;
    transition: border 250ms ease;
    width: 100%;

    :focus {
      border-color: ${primary};
      outline: none;

      + label {
        color: ${primary};
      }
    }
  }

  textarea {
    min-height: ${rem(132)};
  }

  ${media.small`
    max-width: ${rem(600)};
  `}

  ${SpinnerWrapper},
  ${Done} {
    ${position('absolute', `calc(50% - ${rem(32)})`, 'auto', 'auto', '50%')}
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: opacity 250ms ease;
  }

  ${is('busy')`
    ${maskCss}
    ${SpinnerWrapper} {
      opacity: 1;
      z-index: 2;
    }
  `}

  ${is('done')`
    ${maskCss}
    ${Done} {
      opacity: 1;
      z-index: 2;
    }
  `}
`

const Error = styled.mark`
  background: ${slate};
  border-radius: ${rem(4)};
  color: ${white};
  display: block;
  margin: 0 0 ${rem(16)};
  padding: ${rem(12)} ${rem(16)};
`

const CommentForm = ({ comments: commentsStore, postId, onSuccess }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          config {
            recaptchaSiteKey
          }
        }
      }
    }
  `)
  const { recaptchaSiteKey } = data.site.siteMetadata.config
  const { firestore } = commentsStore
  const nameRef = useRef(null)
  const commentRef = useRef(null)
  const [name, setName] = useState('')
  const [comment, setComment] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaInstance, setRecaptchaInstance] = useState()

  const handleNameChange = useCallback(
    event => setName(event.target.value.trim()),
    []
  )

  const handleCommentChange = useCallback(
    event => setComment(event.target.value.trim()),
    []
  )

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()

      if (isSubmitting) return
      if (!isVerified) {
        setError('Our system was unable to save your comment.')
        return
      }

      setIsSubmitting(true)
      const success = await commentsStore.addComment(postId, { name, comment })
      setIsSubmitting(false)

      if (success) {
        setSuccess(true)
        nameRef.current.value = ''
        commentRef.current.value = ''
        setIsVerified(false)
        if (error) {
          setError('')
        } else if (onSuccess) {
          onSuccess()
        }
      } else {
        setError('An error occurred saving your comment.')
      }
    },
    [
      comment,
      commentRef,
      commentsStore,
      error,
      isSubmitting,
      isVerified,
      name,
      nameRef,
      onSuccess,
      postId,
    ]
  )

  const handleVerify = useCallback(() => {
    if (!isVerified) setIsVerified(true)
  }, [isVerified])

  useEffect(() => {
    if (!firestore) return
    setIsInitialized(true)
  }, [firestore, setIsInitialized])

  useEffect(() => {
    if (!showForm) return
    nameRef.current.focus()
  }, [showForm, nameRef])

  useEffect(() => {
    if (!recaptchaInstance) return
    recaptchaInstance.execute()
  }, [recaptchaInstance])

  if (!isInitialized) return null

  if (!showForm) {
    return (
      <Button kind="primary" onClick={() => setShowForm(true)}>
        Add a Comment
      </Button>
    )
  }
  return (
    <Form onSubmit={handleSubmit} busy={isSubmitting} done={success}>
      <p>
        <input
          ref={nameRef}
          id="name"
          name="name"
          onChange={handleNameChange}
          autoComplete="off"
        />
        <label htmlFor="name">Name</label>
      </p>
      <p>
        <textarea
          ref={commentRef}
          id="comment"
          name="comment"
          rows={5}
          onChange={handleCommentChange}
        />
        <label htmlFor="comment">Comment</label>
      </p>
      <Recaptcha
        ref={r => setRecaptchaInstance(r)}
        render="explicit"
        size="invisible"
        sitekey={recaptchaSiteKey}
        verifyCallback={handleVerify}
      />
      {error && <Error>Uh oh. {error}</Error>}
      <Button kind="primary" type="submit" disabled={!name || !comment}>
        Submit
        <Arrow rotate={0} size={16} fill={white} />
      </Button>
      <Spinner />
      {success && <Done />}
    </Form>
  )
}

export default inject('comments')(observer(CommentForm))
