import React, { Fragment, useRef, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TimeAgo from 'react-timeago'
import styled from 'styled-components'
import { rem, minWidth, position, is, flex } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Spinner from 'components/icons/spinner'
import CommentForm from 'components/comment-form'

const { white, ghost, bone, silver, coal, replyBlue } = theme.colors

const Container = styled.div`
  border-top: ${rem(1)} solid ${bone};
  margin: 0 ${rem(16)};
  padding: ${rem(48)} 0;
`

const Wrapper = styled.div`
  margin: 0;
  max-width: ${rem(1000)};
  text-align: left;

  ${minWidth(1032)`
    margin: 0 auto;
  `}
`

const CommentList = styled.ol`
  list-style: none;
  margin: ${rem(24)} 0;

  ${media.small`
    max-width: ${rem(632)};
  `}
`

const Comment = styled.li`
  ${flex('column', 'flex-start', 'flex-start')}

  & + li {
    margin-top: ${rem(16)};
  }

  blockquote {
    background: ${ghost};
    border: 0;
    border-radius: ${rem(8)};
    color: ${coal};
    margin: 0 ${rem(32)} 0 0;
    padding: ${rem(12)} ${rem(16)};
    position: relative;

    :after {
      ${position('absolute', `calc(100% - ${rem(4)})`, 'auto', 'auto', 4)}
      border: ${rem(16)} solid transparent;
      border-top-width: 0;
      border-left-color: ${ghost};
      content: '';
      transform: skew(-30deg, 0);
    }
  }

  small {
    color: ${silver};
    font-size: ${rem(12)};
    margin: 0 ${rem(16)};
    user-select: none;
  }

  cite {
    font-style: normal;
    font-weight: 700;
  }

  time:before {
    content: '\u2014';
    padding: 0 ${rem(4)};
  }

  ${is('recent')`
    blockquote {
      @keyframes fadeInRecent {
        0% {
          background: ${coal};
        }
        100% {
          background: ${ghost};
        }
      }
      @keyframes fadeInRecentNotch {
        0% {
          border-left-color: ${coal};
        }
        100% {
          border-left-color: ${ghost};
        }
      }
      animation: fadeInRecent 2s ease;
      :after {
        animation: fadeInRecentNotch 2s ease;
      }
    }
  `}
`

const Reply = styled(Comment)`
  align-items: flex-end;

  blockquote {
    background: ${replyBlue};
    color: ${white};
    margin: 0 0 0 ${rem(32)};

    :after {
      border-left-color: transparent;
      border-right-color: ${replyBlue};
      left: auto;
      right: ${rem(4)};
      transform: skew(30deg, 0);
    }
  }
`

const Comments = ({ comments: commentsStore, postId }) => {
  const { commentsCache, firestore } = commentsStore
  const containerRef = useRef(null)
  const hasComments = commentsCache.has(postId)
  const [isLoading, setIsLoading] = useState(!hasComments)
  const comments = hasComments ? commentsCache.get(postId) : []
  const now = new Date().valueOf()

  useEffect(() => {
    if (hasComments || !firestore) return
    const fetchComments = async () => {
      await commentsStore.fetchComments(postId)
      setIsLoading(false)
    }
    fetchComments()
  }, [commentsStore, firestore, hasComments, postId])

  return (
    <Container ref={containerRef}>
      <Wrapper>
        {isLoading && <Spinner />}
        {comments.length > 0 && (
          <>
            <h2>Comments</h2>
            <CommentList>
              {comments.map(({ id, name, comment, createdAt, replies }) => (
                <Fragment key={`comment-${id}`}>
                  <Comment recent={now - createdAt < 60000}>
                    <blockquote>{comment}</blockquote>
                    <small>
                      <cite>{name}</cite>
                      <TimeAgo date={createdAt} />
                    </small>
                  </Comment>
                  {replies.map(reply => (
                    <Reply key={`reply-${id}-${reply.createdAt}`}>
                      <blockquote>{reply.comment}</blockquote>
                      <small>
                        <cite>{reply.name}</cite>
                        <TimeAgo date={reply.createdAt} />
                      </small>
                    </Reply>
                  ))}
                </Fragment>
              ))}
            </CommentList>
          </>
        )}
        <CommentForm postId={postId} />
      </Wrapper>
    </Container>
  )
}

export default inject('comments')(observer(Comments))
