import { useStaticQuery, graphql } from 'gatsby'

export default (props = {}) => {
  const { limit = 0, reverse = false } = props
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {
        sourceInstanceName: {
          eq: "blogArticles"
        }
      }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                path
                title
                date
                description
              }
            }
          }
        }
      }
    }
  `)
  const articles = []

  Object.values(data.allFile.edges).forEach(edge => {
    const article = edge.node.childMarkdownRemark.frontmatter
    const year = article.path.replace(/^\/blog\/(\d+)\/.*/, '$1')
    articles.push({ ...article, year })
  })
  articles.sort((a, b) => {
    if (a.date < b.date) return -1
    if (a.date > b.date) return 1
    return 0
  })

  if (reverse) articles.reverse()

  return limit ? articles.slice(0, limit) : articles
}
