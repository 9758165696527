import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 48 48"
    fill={fill}
    {...rest}
  >
    <rect height="5" rx="2" width="42" x="4" y="8"/>
    <rect height="5" rx="2" width="42" x="4" y="21"/>
    <rect height="5" rx="2" width="42" x="4" y="35"/>
  </svg>
)
