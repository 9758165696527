import React from 'react'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'

export const SpinnerWrapper = styled.span.attrs(({ size }) => ({
  style: { width: rem(size), height: rem(size) },
}))`
  display: inline-block;
`

export default ({ fill = theme.colors.black, size = 64 }) => (
  <SpinnerWrapper size={size}>
    <svg width="100%" height="100%" viewBox="0 0 50 50">
      <path
        fill={fill}
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </SpinnerWrapper>
)
