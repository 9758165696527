import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, flex, is, swap, position } from 'styled-tidy'
import { Link } from 'gatsby'
import fetchArticles from 'lib/fetch-articles'
import theme from 'lib/styles/theme'
import MenuItem from 'components/menu-item'

const { colors, fonts } = theme
const { bone, tundra, slate, black } = colors

const Container = styled.ul`
  margin: 0;
  text-align: left;
  user-select: none;
`

const YearButton = styled.a`
  border-bottom: ${rem(1)} solid ${bone};
  color: ${tundra};
  display: block;
  font-family: ${fonts.secondary};
  font-size: ${rem(14)};
  font-weight: 700;
  padding: ${rem(8)} 0;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: all 250ms ease;

  :before {
    ${flex('row', 'center', 'center')}
    ${position('absolute', 0, '100%', 0, 'auto')}
    color: ${black};
    content: '\u2023';
    font-size: ${rem(20)};
    padding: ${rem(8)} 0;
    opacity: 0;
    transition: all 250ms ease;
  }

  :hover,
  :focus {
    background: none;
    box-shadow: inset ${rem(-8)} 0 0 ${black};
    color: ${slate};
    outline: none;
  }

  ${is('current')`
    box-shadow: inset ${rem(-8)} 0 0 ${black};
    color: ${slate};

    :before {
      opacity: 1;
      right: calc(100% + ${rem(8)});
    }
  `}
`

const ArticleList = styled.ul`
  display: ${swap('open', 'block', 'none')};
  margin: 0 0 ${rem(24)} ${rem(3)};
  padding-top: ${rem(8)};
`

export default ({ currentPath = '' }) => {
  const [openYear, setOpenYear] = useState(
    currentPath.replace(/^\/blog\/(\d+).*/, '$1')
  )
  const yearClickHanlder = year => () => {
    setOpenYear(year === openYear ? '' : year)
  }
  const articles = fetchArticles()
  const articlesByYear = []

  articles.forEach(article => {
    const first = articlesByYear[0]
    if (!first || first.year !== article.year) {
      articlesByYear.unshift({
        year: article.year,
        articles: [article],
      })
    } else {
      first.articles.unshift(article)
    }
  })

  return (
    <Container>
      {articlesByYear.map(({ year, articles }) => (
        <li key={year}>
          <YearButton
            role="button"
            tabIndex={0}
            current={year === openYear ? 1 : 0}
            onClick={yearClickHanlder(year)}
            onKeyPress={yearClickHanlder(year)}
          >
            {year}
          </YearButton>
          <ArticleList open={year === openYear}>
            {articles.map(({ title, path }) => (
              <MenuItem key={path}>
                {path === currentPath ? (
                  <span>{title}</span>
                ) : (
                  <Link to={path}>{title}</Link>
                )}
              </MenuItem>
            ))}
          </ArticleList>
        </li>
      ))}
    </Container>
  )
}
